.contact-detail {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.contact-detail.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #f5f5f5;
  border-radius: 30px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: #e0e0e0;
  transform: translateX(-5px);
}

.back-icon {
  margin-right: 8px;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.back-button:hover .back-icon {
  transform: translateX(-3px);
}

.contact-type {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 500;
  animation: fadeInRight 0.5s ease;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.type-icon {
  margin-right: 8px;
  font-size: 14px;
}

.contact-type.person {
  background-color: #e3f2fd;
  color: #1565c0;
}

.contact-type.building {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.contact-main-info {
  margin-bottom: 40px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  animation: fadeIn 0.8s ease;
}

.main-info-icon {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.avatar-icon {
  font-size: 36px;
  color: #006837;
}

.main-info-text {
  flex: 1;
}

.contact-name {
  margin: 0 0 10px 0;
  font-size: 28px;
  color: #333;
}

.contact-position {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #555;
  font-style: italic;
}

.position-icon {
  margin-right: 8px;
  color: #777;
}

.contact-sections-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.animate-section {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.contact-section {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.contact-section:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
  transform: translateY(-5px);
}

.contact-section h2 {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #006837;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f0f0;
}

.section-icon {
  margin-right: 10px;
  font-size: 18px;
}

.contact-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 12px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
}

.info-item:hover {
  background-color: #f0f7ff;
  transform: translateY(-3px);
}

.whatsapp-item:hover {
  background-color: #e7f7e7;
}

.email-item:hover {
  background-color: #fff4e5;
}

.info-label {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
}

.label-icon {
  margin-right: 6px;
  font-size: 14px;
  color: #777;
}

.whatsapp-icon {
  color: #25D366;
}

.email-icon {
  color: #F57C00;
}

.info-value {
  font-size: 16px;
  word-break: break-word;
}

.status-badge {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 20px;
  background-color: #e8f5e9;
  color: #2e7d32;
  font-weight: 500;
  font-size: 14px;
}

.info-value a {
  color: #1976d2;
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.info-value a:hover {
  color: #0d47a1;
  text-decoration: underline;
}

.contact-address {
  line-height: 1.6;
  font-size: 16px;
  color: #444;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.contact-description {
  line-height: 1.6;
  font-size: 16px;
  color: #444;
}

.detail-loading {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading, .error, .not-found {
  text-align: center;
  padding: 50px;
  font-size: 18px;
}

.spinner-icon {
  font-size: 40px;
  color: #006837;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.error {
  color: #d32f2f;
}

.not-found {
  color: #f57c00;
}

@media (max-width: 768px) {
  .contact-info-grid {
    grid-template-columns: 1fr;
  }
  
  .contact-main-info {
    flex-direction: column;
    text-align: center;
  }
  
  .main-info-icon {
    margin: 0 auto 20px;
  }
}

.contact-created {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

.calendar-icon {
  margin-right: 8px;
  color: #888;
}

.id-badge {
  display: inline-block;
  background-color: #f0f0f0;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
  color: #666;
  font-weight: 500;
}

.raw-data-section {
  overflow: hidden;
}

.raw-data-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 15px;
  transition: all 0.3s ease;
}

.raw-data-header:hover {
  color: #004d25;
}

.toggle-icon {
  color: #006837;
  font-size: 18px;
  transition: transform 0.3s ease;
}

.raw-data {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  overflow-x: auto;
  margin-top: 10px;
  animation: slideDown 0.3s ease;
  border: 1px solid #e0e0e0;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.raw-data pre {
  font-family: 'Courier New', monospace;
  font-size: 13px;
  white-space: pre-wrap;
  color: #333;
}

/* Share Feature Styles */
.contact-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.share-container {
  position: relative;
}

.share-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #006837;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #004d25;
}

.share-icon {
  font-size: 14px;
}

.share-options-container {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 100;
  animation: fadeIn 0.2s ease;
}

.share-options {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  min-width: 220px;
}

.share-option {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 12px 16px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  color: #333;
  font-size: 14px;
}

.share-option:hover {
  background-color: #f5f5f5;
}

.share-option:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.option-icon {
  color: #666;
  font-size: 16px;
}

.copy-success {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #4CAF50;
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles for Contact Detail */
@media (max-width: 480px) {
  .contact-detail-container {
    padding: 10px;
  }
  
  .contact-header {
    padding: 15px;
  }
  
  .contact-header h1 {
    font-size: 20px;
  }
  
  .contact-title {
    font-size: 14px;
  }
  
  .contact-section {
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .contact-section-title {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .contact-info-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  
  .contact-info-label {
    width: 100%;
    margin-bottom: 3px;
  }
  
  .share-button {
    width: 30px;
    height: 30px;
  }
  
  .share-options-container {
    right: 0;
    width: 220px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .contact-detail-container {
    padding: 15px;
  }
  
  .contact-header {
    padding: 20px;
  }
  
  .contact-header h1 {
    font-size: 24px;
  }
  
  .contact-title {
    font-size: 16px;
  }
  
  .contact-section {
    padding: 20px;
  }
  
  .contact-info-label {
    min-width: 120px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contact-detail-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
}

@media (min-width: 1025px) {
  .contact-detail-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
  }
} 