.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
}

.contact-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  padding: 18px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
  height: 220px;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(0, 104, 55, 0.1);
  margin-bottom: 10px;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

.contact-card.person {
  border-top: none;
  border-left: 4px solid #2196f3;
}

.contact-card.building {
  border-top: none;
  border-left: 4px solid #4caf50;
}

.animate-card {
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.card-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  transition: all 0.3s ease;
}

.person-icon {
  color: #2196f3;
  font-size: 16px;
}

.building-icon {
  color: #4caf50;
  font-size: 16px;
}

.card-name {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  flex: 1;
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-designation, .card-department, .card-institute {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
  color: #555;
  transition: all 0.3s ease;
  min-height: 20px;
}

.card-designation:hover, .card-department:hover, .card-institute:hover {
  color: #000;
}

.card-designation {
  font-style: italic;
}

.icon-small {
  margin-right: 8px;
  font-size: 14px;
  color: #777;
  min-width: 16px;
}

.card-contact-info {
  margin-top: 8px;
  font-size: 12px;
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.contact-card:hover .card-contact-info {
  background-color: #f0f7ff;
}

.card-phone, .card-email {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-department {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #eee;
}

.card-status {
  margin-top: 6px;
  font-size: 11px;
  color: #777;
}

.view-details {
  margin-top: auto;
  padding-top: 8px;
  text-align: center;
  color: #006837;
  font-weight: 500;
  font-size: 14px;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.contact-card:hover .view-details {
  color: #004d25;
  transform: translateY(-2px);
}

.arrow-icon {
  font-size: 12px;
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.contact-card:hover .arrow-icon {
  transform: translateX(4px);
}

/* Animation delay for cards to create a staggered effect */
.animate-card:nth-child(1) { animation-delay: 0.05s; }
.animate-card:nth-child(2) { animation-delay: 0.1s; }
.animate-card:nth-child(3) { animation-delay: 0.15s; }
.animate-card:nth-child(4) { animation-delay: 0.2s; }
.animate-card:nth-child(5) { animation-delay: 0.25s; }
.animate-card:nth-child(6) { animation-delay: 0.3s; }
.animate-card:nth-child(7) { animation-delay: 0.35s; }
.animate-card:nth-child(8) { animation-delay: 0.4s; }
.animate-card:nth-child(9) { animation-delay: 0.45s; }
.animate-card:nth-child(10) { animation-delay: 0.5s; }
.animate-card:nth-child(11) { animation-delay: 0.55s; }
.animate-card:nth-child(12) { animation-delay: 0.6s; }

.card-badge {
  background-color: #f5f5f5;
  color: #555;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-left: 6px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.contact-card:hover .card-badge {
  background-color: #e0e0e0;
} 