body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f8f9fa;
  color: #333;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  padding: 8px 15px;
  color: white;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.App-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" opacity="0.05"><path d="M0 0 L100 0 L100 100 L0 100 Z" fill="none" stroke="white" stroke-width="1"/></svg>');
  opacity: 0.2;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.header-logo {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 15px;
}

.site-logo {
  height: 35px;
  width: auto;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

.site-logo:hover {
  transform: scale(1.05);
}

.App-header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  animation: fadeInDown 0.8s ease-out;
  color: white;
}

.header-nav {
  display: flex;
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.nav-item {
  position: relative;
}

.menu-open .nav-item {
  opacity: 1;
  transform: translateY(0);
}

/* Staggered animation for menu items */
.menu-open .nav-item:nth-child(1) { transition-delay: 0.05s; }
.menu-open .nav-item:nth-child(2) { transition-delay: 0.1s; }
.menu-open .nav-item:nth-child(3) { transition-delay: 0.15s; }
.menu-open .nav-item:nth-child(4) { transition-delay: 0.2s; }
.menu-open .nav-item:nth-child(5) { transition-delay: 0.25s; }
.menu-open .nav-item:nth-child(6) { transition-delay: 0.3s; }

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.nav-icon {
  font-size: 13px;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .App-header {
    padding: 15px;
  }
  
  .header-container {
    flex-direction: column;
    gap: 15px;
  }
  
  .App-header h1 {
    font-size: 20px;
  }
  
  .nav-menu {
    gap: 10px;
  }
  
  .nav-link {
    font-size: 14px;
    padding: 6px 10px;
  }
  
  .site-logo {
    height: 35px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-main-content {
  min-height: calc(100vh - 180px); /* Adjust based on your header and footer height */
  padding-bottom: 30px;
}

.app-footer {
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  padding: 20px 0;
  margin-top: 20px;
  color: #555;
}

.app-footer .footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.app-footer .copyright-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.app-footer .attribution {
  font-size: 13px;
  color: #777;
  line-height: 1.5;
}

.app-footer .contact-stats {
  font-size: 11px;
  color: #999;
  margin-top: 15px;
  font-style: italic;
}

.mobile-app-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.app-store-link {
  display: flex;
  align-items: center;
  background-color: #006837;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.app-store-link:hover {
  background-color: #00552d;
}

.app-store-icon {
  margin-right: 8px;
  font-size: 16px;
}

@media (max-width: 600px) {
  .mobile-app-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .app-store-link {
    width: 200px;
    justify-content: center;
  }
}

.header-app-links {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 6px;
  margin-top: 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  animation: pulse 2s infinite;
  position: relative;
  overflow: hidden;
}

.header-app-links::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: shine 3s infinite;
}

.header-app-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: white;
}

.header-store-buttons {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.header-store-link {
  display: flex;
  align-items: center;
  background-color: white;
  color: #006837;
  padding: 8px 16px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.header-store-link:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-store-icon {
  margin-right: 8px;
  font-size: 16px;
}

@media (max-width: 600px) {
  .header-app-links {
    padding: 8px;
    margin-top: 10px;
  }
  
  .header-app-label {
    font-size: 14px;
  }
  
  .header-store-buttons {
    gap: 10px;
  }
  
  .header-store-link {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes shine {
  100% {
    left: 100%;
  }
}

.app-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.popup-content {
  background-color: white;
  padding: 25px 35px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
  max-width: 300px;
  animation: slideIn 0.3s ease;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #777;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-popup:hover {
  color: #333;
}

.popup-icon {
  font-size: 48px;
  color: #006837;
  margin-bottom: 15px;
}

.popup-content p {
  font-size: 18px;
  color: #333;
  margin: 0;
  font-weight: 500;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    transform: translateY(-30px); 
    opacity: 0;
  }
  to { 
    transform: translateY(0); 
    opacity: 1; 
  }
}

.device-specific {
  animation: flash 2s infinite;
  border: 2px solid transparent;
}

.android-highlight {
  border-color: #3ddc84;
  background-color: rgba(0, 0, 0, 0.25);
}

.ios-highlight {
  border-color: #007aff;
  background-color: rgba(0, 0, 0, 0.25);
}

.highlight-store-link {
  background-color: #3ddc84;
  color: #000000;
  font-weight: 700;
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.highlight-store-link:hover {
  background-color: #32b36c;
  transform: scale(1.07) translateY(-2px);
}

.low-emphasis {
  opacity: 0.7;
}

.low-emphasis:hover {
  opacity: 1;
}

.indicator-arrow {
  color: #3ddc84;
  animation: bounce 1s infinite alternate;
  margin: 0 8px;
}

@keyframes flash {
  0%, 100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

/* Responsive Breakpoints */
/* Small Mobile (320px - 480px) */
@media (max-width: 480px) {
  .App-header {
    padding: 6px 8px;
  }
  
  .App-header h1 {
    font-size: 14px;
  }
  
  .site-logo {
    height: 28px;
  }
  
  .header-app-links {
    padding: 5px;
    margin-top: 6px;
  }
}

/* Medium Mobile (481px - 600px) */
@media (min-width: 481px) and (max-width: 600px) {
  .App-header {
    padding: 7px 10px;
  }
  
  .App-header h1 {
    font-size: 16px;
  }
  
  .site-logo {
    height: 30px;
  }
}

/* Tablet (601px - 768px) */
@media (min-width: 601px) and (max-width: 768px) {
  .App-header {
    padding: 8px 12px;
  }
  
  .App-header h1 {
    font-size: 18px;
  }
  
  .site-logo {
    height: 32px;
  }
}

/* Large Tablet (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .App-header {
    padding: 20px;
  }
  
  .header-container {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .App-header h1 {
    font-size: 22px;
  }
  
  .site-logo {
    height: 40px;
  }
  
  .header-app-links {
    width: 90%;
  }
  
  .app-main-content {
    padding: 20px;
  }
}

/* Desktop (1025px - 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {
  .App-header {
    padding: 10px 25px;
  }
  
  .site-logo {
    height: 38px;
  }
}

/* Large Desktop (1441px and above) */
@media (min-width: 1441px) {
  .App-header {
    padding: 12px 30px;
  }
  
  .App-header h1 {
    font-size: 24px;
  }
  
  .site-logo {
    height: 40px;
  }
}

/* Mobile Menu Styles */
.mobile-menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  padding: 3px;
  z-index: 1001;
}

.menu-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1001;
}

.menu-open .menu-close {
  display: flex;
}

.menu-close:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .mobile-menu-toggle:active {
    transform: scale(0.95);
  }
  
  .header-container {
    position: relative;
    flex-wrap: nowrap;
    padding: 5px 10px;
    z-index: 1001;
  }
  
  .header-logo {
    flex: 1;
  }
  
  .App-header h1 {
    font-size: 16px;
  }
  
  .header-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(135deg, #006837 0%, #00a651 95%);
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease, visibility 0.3s ease;
    z-index: 1000;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header-nav::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.1;
  }
  
  .header-nav.menu-open {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
    overflow-y: auto;
    padding: 0;
    /* Mobile menu scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1);
  }
  
  .header-nav.menu-open::-webkit-scrollbar {
    width: 6px;
  }
  
  .header-nav.menu-open::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  
  .header-nav.menu-open::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    border: none;
  }
  
  .header-nav.menu-open::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  
  .nav-menu {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    padding: 10px 20px 30px;
    margin-top: 0;
    text-align: center;
  }
  
  .nav-item {
    width: 100%;
    margin-bottom: 12px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .menu-open .nav-item {
    opacity: 1;
    transform: translateY(0);
  }
  
  /* Staggered animation for menu items */
  .menu-open .nav-item:nth-child(1) { transition-delay: 0.15s; }
  .menu-open .nav-item:nth-child(2) { transition-delay: 0.2s; }
  .menu-open .nav-item:nth-child(3) { transition-delay: 0.25s; }
  .menu-open .nav-item:nth-child(4) { transition-delay: 0.3s; }
  .menu-open .nav-item:nth-child(5) { transition-delay: 0.35s; }
  .menu-open .nav-item:nth-child(6) { transition-delay: 0.4s; }
  
  .nav-link {
    width: 100%;
    padding: 12px 15px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.12);
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .nav-icon {
    font-size: 18px;
    margin-right: 10px;
  }
  
  .nav-link:hover, .nav-link:active {
    background-color: rgba(255, 255, 255, 0.25);
    transform: translateY(-2px) scale(1.02);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
  
  .menu-title h2 {
    font-size: 22px;
    margin: 8px 0 0;
  }
  
  .menu-logo {
    width: 45px;
    height: 45px;
    padding: 3px;
  }
}

.menu-title {
  text-align: center;
  color: white;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.menu-open .menu-title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.menu-title h2 {
  font-size: 28px;
  margin: 10px 0 0;
  font-weight: 600;
  letter-spacing: 1px;
}

.menu-logo {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .menu-title h2 {
    font-size: 24px;
  }
  
  .menu-logo {
    width: 50px;
    height: 50px;
  }
}

/* Custom Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #005a30 0%, #009046 100%);
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: #00a651 #f1f1f1;
}

/* Custom scrollbar for specific containers */
.privacy-content, .about-content, .report-content, .contact-content {
  scrollbar-width: thin;
  scrollbar-color: #00a651 #f1f1f1;
}

/* Ensure smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Scrollbar for policy sections with long text */
.section-content {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 15px;
  scrollbar-width: thin;
  scrollbar-color: #00a651 #f1f1f1;
}

.section-content::-webkit-scrollbar {
  width: 8px;
}

.section-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.section-content::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

.section-content::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #005a30 0%, #009046 100%);
}

/* Scrollbar for textareas */
textarea {
  scrollbar-width: thin;
  scrollbar-color: #00a651 #f1f1f1;
}

textarea::-webkit-scrollbar {
  width: 6px;
}

textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  border-radius: 5px;
}

textarea::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #005a30 0%, #009046 100%);
}

