.report-page {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.report-header {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  padding: 80px 20px;
  position: relative;
  text-align: center;
  color: white;
}

.header-overlay {
  position: relative;
  z-index: 1;
}

.header-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" opacity="0.05"><path d="M0 0 L100 0 L100 100 L0 100 Z" fill="none" stroke="white" stroke-width="1"/></svg>');
  opacity: 0.2;
  z-index: -1;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.report-icon {
  font-size: 48px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.report-header h1 {
  font-size: 42px;
  margin: 0;
  font-weight: 700;
}

.report-container {
  max-width: 900px;
  margin: -50px auto 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.back-nav {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #006837;
}

.back-icon {
  font-size: 14px;
}

.report-content {
  padding: 40px 50px;
}

.intro-text {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #333;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.report-form {
  max-width: 700px;
  margin: 0 auto;
}

.error-message {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  color: #ff4d4f;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 25px;
  font-size: 16px;
}

.success-message {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 25px;
  text-align: center;
}

.success-message h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #389e0d;
}

.success-message p {
  font-size: 18px;
  margin-bottom: 20px;
}

.new-report-button {
  background-color: #006837;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.new-report-button:hover {
  background-color: #00552d;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
  font-size: 16px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #006837;
  box-shadow: 0 0 0 3px rgba(0, 104, 55, 0.1);
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

.submit-button {
  background-color: #006837;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 14px 25px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.2s ease;
  width: 100%;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #00552d;
}

.submit-icon {
  font-size: 16px;
}

.report-footer {
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .report-header {
    padding: 60px 20px;
  }
  
  .report-header h1 {
    font-size: 32px;
  }
  
  .report-icon {
    font-size: 36px;
  }
  
  .report-content {
    padding: 30px;
  }
  
  .intro-text {
    font-size: 18px;
  }
  
  .form-group label {
    font-size: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px 12px;
    font-size: 15px;
  }
  
  .submit-button {
    padding: 12px 20px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .report-header {
    padding: 40px 20px;
  }
  
  .report-header h1 {
    font-size: 28px;
  }
  
  .report-icon {
    font-size: 30px;
  }
  
  .report-container {
    margin-top: -30px;
  }
  
  .report-content {
    padding: 20px;
  }
  
  .intro-text {
    font-size: 16px;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 14px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 8px 10px;
    font-size: 14px;
  }
  
  .submit-button {
    padding: 10px 16px;
    font-size: 15px;
  }
} 