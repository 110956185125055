.contact-page {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.contact-header {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  padding: 80px 20px;
  position: relative;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-overlay {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.header-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" opacity="0.05"><path d="M0 0 L100 0 L100 100 L0 100 Z" fill="none" stroke="white" stroke-width="1"/></svg>');
  opacity: 0.2;
  z-index: -1;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.contact-icon {
  font-size: 48px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.contact-header h1 {
  font-size: 42px;
  margin: 0;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.contact-container {
  max-width: 900px;
  margin: -50px auto 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.back-nav {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #006837;
}

.back-icon {
  font-size: 14px;
}

.contact-content {
  padding: 40px 50px;
}

.intro-text {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #333;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.contact-info-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact-info-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.contact-info-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.info-icon-container {
  background-color: #006837;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex-shrink: 0;
}

.info-icon {
  color: white;
  font-size: 22px;
}

.info-content {
  flex-grow: 1;
}

.info-content h3 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.info-content p {
  margin: 5px 0;
  color: #555;
  font-size: 16px;
  line-height: 1.5;
}

.info-content a {
  color: #006837;
  text-decoration: none;
  transition: color 0.2s ease;
}

.info-content a:hover {
  color: #00a651;
  text-decoration: underline;
}

.contact-footer {
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.icon-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .contact-header {
    padding: 60px 20px;
  }
  
  .contact-header h1 {
    font-size: 32px;
  }
  
  .contact-icon {
    font-size: 36px;
  }
  
  .contact-content {
    padding: 30px;
  }
  
  .intro-text {
    font-size: 18px;
  }
  
  .contact-info-item {
    padding: 15px;
  }
  
  .info-icon-container {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }
  
  .info-icon {
    font-size: 18px;
  }
  
  .info-content h3 {
    font-size: 16px;
  }
  
  .info-content p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .contact-header {
    padding: 40px 20px;
  }
  
  .contact-header h1 {
    font-size: 28px;
  }
  
  .contact-icon {
    font-size: 30px;
  }
  
  .contact-container {
    margin-top: -30px;
  }
  
  .contact-content {
    padding: 20px;
  }
  
  .intro-text {
    font-size: 16px;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .contact-info-item {
    padding: 12px;
  }
  
  .info-icon-container {
    width: 35px;
    height: 35px;
    margin-right: 12px;
  }
  
  .info-icon {
    font-size: 16px;
  }
  
  .info-content h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }
  
  .info-content p {
    font-size: 13px;
  }
} 