.privacy-policy-page {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.privacy-header {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  padding: 80px 20px;
  position: relative;
  text-align: center;
  color: white;
}

.header-overlay {
  position: relative;
  z-index: 1;
}

.header-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" opacity="0.05"><path d="M0 0 L100 0 L100 100 L0 100 Z" fill="none" stroke="white" stroke-width="1"/></svg>');
  opacity: 0.2;
  z-index: -1;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.privacy-shield-icon {
  font-size: 48px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.privacy-header h1 {
  font-size: 42px;
  margin: 0 0 10px;
  font-weight: 700;
}

.privacy-container {
  max-width: 1100px;
  margin: -50px auto 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.back-nav {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #006837;
}

.back-icon {
  font-size: 14px;
}

.policy-layout {
  display: flex;
  flex-direction: row;
}

.policy-nav {
  width: 280px;
  padding: 30px 0;
  border-right: 1px solid #eee;
  background-color: #f8f8f8;
  position: sticky;
  top: 0;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.policy-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.policy-nav li {
  margin-bottom: 5px;
}

.policy-nav button {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  text-align: left;
  padding: 12px 20px;
  background: none;
  border: none;
  color: #444;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.policy-nav button:hover {
  background-color: rgba(0, 104, 55, 0.05);
  color: #006837;
}

.policy-nav li.active button {
  background-color: rgba(0, 104, 55, 0.08);
  color: #006837;
  font-weight: 600;
  border-left-color: #006837;
}

.nav-icon {
  font-size: 16px;
  color: #006837;
  opacity: 0.8;
}

.privacy-content {
  flex: 1;
  padding: 40px 50px;
}

.intro-text {
  font-size: 17px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #444;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}

.policy-section {
  margin-bottom: 40px;
  scroll-margin-top: 100px;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.section-icon {
  font-size: 22px;
  color: #006837;
  margin-right: 15px;
}

.policy-section h2 {
  color: #333;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.section-content {
  padding-left: 37px;
}

.policy-section p {
  margin-top: 0;
  line-height: 1.6;
  color: #444;
  font-size: 16px;
}

.policy-section ul {
  padding-left: 20px;
  margin-top: 15px;
}

.policy-section li {
  margin-bottom: 10px;
  line-height: 1.5;
  color: #444;
}

.contact-box {
  background-color: #f8f8f8;
  padding: 25px;
  border-radius: 10px;
  margin-top: 20px;
  border-left: 4px solid #006837;
}

.policy-section address {
  font-style: normal;
  line-height: 1.6;
}

.policy-section address strong {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.policy-section a {
  color: #006837;
  text-decoration: none;
}

.policy-section a:hover {
  text-decoration: underline;
}

.privacy-footer {
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 14px;
  color: #666;
}

/* Responsive styles */
@media (max-width: 900px) {
  .policy-layout {
    flex-direction: column;
  }
  
  .policy-nav {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    max-height: none;
    position: relative;
  }
  
  .policy-nav ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;
  }
  
  .policy-nav li {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .policy-nav button {
    padding: 8px 12px;
    font-size: 14px;
    border-left: none;
    border-radius: 20px;
  }
  
  .policy-nav li.active button {
    border-left: none;
  }
  
  .privacy-content {
    padding: 30px;
  }
}

@media (max-width: 600px) {
  .privacy-policy-page {
    padding-bottom: 20px;
  }
  
  .privacy-header {
    padding: 50px 20px;
  }
  
  .privacy-shield-icon {
    font-size: 36px;
  }
  
  .privacy-header h1 {
    font-size: 28px;
  }
  
  .privacy-container {
    margin-top: -30px;
    border-radius: 15px 15px 0 0;
  }
  
  .back-nav {
    padding: 15px 20px;
  }
  
  .policy-nav {
    padding: 10px 0;
  }
  
  .policy-nav ul {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
    -webkit-overflow-scrolling: touch;
  }
  
  .policy-nav button {
    white-space: nowrap;
    padding: 6px 10px;
    font-size: 13px;
  }
  
  .nav-icon {
    font-size: 14px;
  }
  
  .privacy-content {
    padding: 20px;
  }
  
  .section-header {
    margin-bottom: 15px;
  }
  
  .section-icon {
    font-size: 18px;
    margin-right: 10px;
  }
  
  .policy-section h2 {
    font-size: 18px;
  }
  
  .section-content {
    padding-left: 28px;
  }
  
  .intro-text {
    font-size: 15px;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .policy-section {
    margin-bottom: 30px;
  }
  
  .contact-box {
    padding: 15px;
  }
} 