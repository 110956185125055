.contacts-directory {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.directory-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  position: relative;
}

.header-icon {
  font-size: 28px;
  color: #006837;
  margin-right: 15px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.contacts-directory h2 {
  text-align: center;
  margin: 0;
  color: #006837;
  font-size: 28px;
  font-weight: 600;
}

.search-container {
  max-width: 900px;
  margin: 0 auto 30px;
  animation: fadeIn 0.8s ease-in-out;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 15px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.search-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-row:last-child {
  margin-bottom: 0;
}

.search-box {
  position: relative;
  flex: 1;
  min-width: 250px;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.input-wrapper {
  position: relative;
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.search-box:focus-within .input-wrapper {
  background-color: #fff;
  box-shadow: 0 0 0 2px #006837;
}

.search-icon {
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 16px;
  transition: color 0.3s ease;
  z-index: 1;
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 18px 20px 18px 50px;
  padding-right: 40px;
  border: none;
  border-radius: 12px;
  background-color: transparent;
  font-size: 16px;
  color: #333;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
}

.search-input::placeholder {
  color: #aaa;
  transition: opacity 0.3s ease;
}

.search-input:focus::placeholder {
  opacity: 0.5;
}

.search-label {
  position: absolute;
  top: -10px;
  left: 12px;
  background-color: #fff;
  padding: 0 8px;
  font-size: 12px;
  color: #006837;
  font-weight: 600;
  border-radius: 10px;
  z-index: 5;
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.3s ease;
  pointer-events: none;
  box-shadow: 0 0 0 1px #fff;
}

.search-box:focus-within .search-label {
  opacity: 1;
  transform: translateY(0);
}

.clear-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 5px;
  border-radius: 50%;
  color: #aaa;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  font-size: 14px;
  z-index: 2;
}

.clear-button:hover {
  color: #d32f2f;
  background-color: rgba(0, 0, 0, 0.05);
}

.clear-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 104, 55, 0.2);
}

.clear-all-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f1f1f1;
  color: #666;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.clear-all-button:hover {
  background-color: #e0e0e0;
  color: #d32f2f;
}

.clear-all-icon {
  font-size: 12px;
}

.contacts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px 20px;
  margin-bottom: 30px;
}

.loading, .error {
  text-align: center;
  padding: 80px 20px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 500px;
}

.loading-text {
  margin-top: 20px;
  width: 100%;
}

.progress-container {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  margin: 15px 0;
  overflow: hidden;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #006837 0%, #00a651 100%);
  border-radius: 10px;
  transition: width 0.5s ease;
  box-shadow: 0 2px 5px rgba(0, 104, 55, 0.3);
  position: relative;
  overflow: hidden;
}

.progress-bar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 30px 30px;
  animation: progressAnimation 1s linear infinite;
  z-index: 1;
}

@keyframes progressAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}

.progress-text {
  font-size: 16px;
  font-weight: 600;
  color: #006837;
  margin: 0;
}

.loading-details {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.spinner-icon {
  font-size: 40px;
  color: #006837;
  margin-bottom: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.error {
  color: #d32f2f;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
}

.pagination-button {
  background-color: #006837;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 104, 55, 0.2);
}

.pagination-button:hover:not(:disabled) {
  background-color: #004d25;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 104, 55, 0.3);
}

.pagination-button:disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-icon {
  font-size: 14px;
}

.pagination-button:first-child .pagination-icon {
  margin-right: 8px;
}

.pagination-button:last-child .pagination-icon {
  margin-left: 8px;
}

.page-indicator {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  background-color: #f5f5f5;
  padding: 8px 16px;
  border-radius: 20px;
}

/* Responsive design */
@media (max-width: 1200px) {
  .contacts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .contacts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .contacts-grid {
    grid-template-columns: 1fr;
  }
  
  .pagination {
    flex-direction: column;
    gap: 10px;
  }
}

.card-container {
  position: relative;
  padding-bottom: 15px;
}

.card-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 10%;
  right: 10%;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(0, 104, 55, 0.2), transparent);
}

.no-results {
  text-align: center;
  padding: 40px 20px;
  font-size: 16px;
  color: #666;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  margin-bottom: 30px;
  animation: fadeIn 0.5s ease-in-out;
}

.directory-footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.contact-count {
  font-size: 14px;
  color: #666;
  background-color: #f5f5f5;
  padding: 5px 12px;
  border-radius: 20px;
  font-weight: 500;
}

.main-footer {
  margin-top: 50px;
  padding: 30px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 14px;
  color: #666;
}

.footer-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright-text {
  margin-bottom: 12px;
  font-weight: 500;
}

.attribution {
  line-height: 1.5;
  color: #888;
  font-size: 13px;
  text-align: center;
  margin-bottom: 20px;
}

.attribution div {
  margin-bottom: 4px;
}

.privacy-policy-link {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f1f1f1;
  color: #666;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 15px;
  text-decoration: none;
}

.privacy-policy-link:hover {
  background-color: #e0e0e0;
  color: #006837;
}

.privacy-icon {
  color: #006837;
  font-size: 14px;
}

.toggle-icon {
  font-size: 12px;
  margin-left: 5px;
}

/* Remove the unused privacy policy container and content styles */
.privacy-policy-container, 
.privacy-policy-content,
.privacy-policy-content h3,
.effective-date,
.intro-text,
.policy-section,
.policy-section h4,
.policy-section p,
.policy-section ul,
.policy-section li,
.policy-section address,
.policy-section a,
.policy-section a:hover,
.toggle-icon {
  /* These styles will be removed */
}

@media (max-width: 600px) {
  .main-footer {
    padding: 20px 0;
    font-size: 12px;
  }
  
  .copyright-text {
    margin-bottom: 10px;
  }
  
  .attribution {
    font-size: 11px;
  }
  
  .privacy-policy-container {
    padding: 20px 15px;
    margin-top: 20px;
  }
  
  .privacy-policy-content h3 {
    font-size: 20px;
  }
  
  .privacy-policy-content h4 {
    font-size: 16px;
  }
  
  .policy-section {
    margin-bottom: 20px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
  animation: fadeIn 0.5s ease;
}

@media (max-width: 480px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  .search-container {
    flex-direction: column;
    align-items: stretch;
  }
  
  .search-field {
    margin-bottom: 10px;
  }
  
  .pagination {
    flex-direction: column;
    gap: 10px;
  }
  
  .pagination-button {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 15px;
  }
  
  .search-container {
    flex-wrap: wrap;
  }
  
  .search-field {
    flex: 1 1 200px;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media (min-width: 1025px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 25px;
  }
} 