.about-page {
  min-height: 100vh;
  background-color: #f9f9f9;
}

.about-header {
  background: linear-gradient(135deg, #006837 0%, #00a651 100%);
  padding: 80px 20px;
  position: relative;
  text-align: center;
  color: white;
}

.header-overlay {
  position: relative;
  z-index: 1;
}

.header-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100" opacity="0.05"><path d="M0 0 L100 0 L100 100 L0 100 Z" fill="none" stroke="white" stroke-width="1"/></svg>');
  opacity: 0.2;
  z-index: -1;
}

.header-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-icon {
  font-size: 48px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.about-header h1 {
  font-size: 42px;
  margin: 0;
  font-weight: 700;
}

.about-container {
  max-width: 900px;
  margin: -50px auto 50px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.back-nav {
  padding: 20px 30px;
  border-bottom: 1px solid #eee;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: #666;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s ease;
}

.back-button:hover {
  color: #006837;
}

.back-icon {
  font-size: 14px;
}

.about-content {
  padding: 40px 50px;
}

.intro-text {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 40px;
  color: #333;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
}

.about-section {
  margin-bottom: 40px;
}

.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.section-icon {
  font-size: 24px;
  color: #006837;
  margin-right: 15px;
}

.about-section h2 {
  color: #333;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
}

.section-content {
  padding-left: 40px;
}

.section-content p {
  margin-top: 0;
  line-height: 1.6;
  color: #444;
  font-size: 17px;
}

.department-image-container {
  margin-top: 30px;
  text-align: center;
}

.department-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.version-info {
  margin-top: 50px;
  padding: 15px 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  color: #666;
  border-left: 4px solid #006837;
}

.version-icon {
  color: #006837;
}

.about-footer {
  padding: 20px 0;
  background-color: #f9f9f9;
  border-top: 1px solid #eaeaea;
  text-align: center;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .about-header {
    padding: 60px 20px;
  }
  
  .about-header h1 {
    font-size: 32px;
  }
  
  .about-icon {
    font-size: 36px;
  }
  
  .about-content {
    padding: 30px;
  }
  
  .intro-text {
    font-size: 18px;
  }
  
  .section-content {
    padding-left: 30px;
  }
}

@media (max-width: 480px) {
  .about-header {
    padding: 40px 20px;
  }
  
  .about-header h1 {
    font-size: 28px;
  }
  
  .about-icon {
    font-size: 30px;
  }
  
  .about-container {
    margin-top: -30px;
  }
  
  .about-content {
    padding: 20px;
  }
  
  .intro-text {
    font-size: 16px;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }
  
  .section-header {
    margin-bottom: 15px;
  }
  
  .section-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .about-section h2 {
    font-size: 20px;
  }
  
  .section-content {
    padding-left: 20px;
  }
  
  .section-content p {
    font-size: 15px;
  }
  
  .version-info {
    margin-top: 30px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }
} 